/* You can add global styles to this file, and also import other style files */

.theme-btn {
  background: linear-gradient(180deg, #fd7f00 0%, #e16301 100%);
  box-shadow: 0px 8px 8px rgba(251, 125, 1, 0.15);
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #f7f7f7;
  padding: 14px 49px;
  margin-left: 40px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.btn-bg {
  background: linear-gradient(180deg, #fd7f00 0%, #e16301 100%);
  border-radius: 2px !important;
}
.text-orange {
  color: #ff7103;
}
.wh-inherit {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.page_wrapper {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}
.border-color {
  border: 1px solid rgba(255, 113, 3, 0.15) !important;
  border-radius: 2px !important;
}
.bg-blur {
  width: 268px;
  height: 268px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(65 70 96 / 58%) 100%
  );
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 20px 20px;
  top: 0px;
}
.border-line {
  left: 15.25%;
  right: 6.25%;
  bottom: 22.83%;
}
.wh-10 {
  width: 10px;
  height: 10px;
}
.text-change {
  font-family: "Raleway";
}
.lang-switcher {
  bottom: 0px;
  right: 81px;
  z-index: 999999;
  li {
    margin: 5px;
    opacity: 0.7;
  }
  .active a {
    border-bottom: 2px solid #ffffff;
    background-color: #ffffff;
    color: #3c3738 !important;
  }
}
.bottom-55 {
  bottom: 55px;
}
.text-light-brown {
  color: #3c3738;
  letter-spacing: 0.5px;
}
.bg-wh {
  width: 28px;
  height: 22px;
}
.vertical-row {
  height: 22px;
  width: 2px;
  background-color: #ffffff;
  bottom: 8px;
  left: 45px;
}
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
.modal{
  z-index : 1055 !important;
}
.barcode svg {
    width: 100%;
}