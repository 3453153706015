// Core

body {
  margin: 0 auto;
  padding: 0;
  font-family: 'Circe';
  background: #f7f7f7;
}


@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/CRC55.woff') format('woff');
}

.cursor-pointer{
  cursor: pointer;
}

@import "custom/variables";
@import "custom/icon";
@import "custom/typograpy";
@import "custom/button";
@import "custom/navbar";
@import "custom/table";

@import "bootstrap/scss/bootstrap";

// regular style toast
@import 'ngx-toastr/toastr';
