.navbar-vertical {
  background-color: $secondary;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);

  .nav {
    .nav-link {
      color: $white;

      &:hover {}

      &.active {
        color: $black;
      }
    }
  }
}
