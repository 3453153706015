.table-hub {
    border-collapse: separate;
    border-spacing: 0;
  
    th {
      &:first-child {
        border-top-left-radius: 6px;
      }
      &:last-child {
        border-top-right-radius: 6px;
      }
    }
  
    tr {
      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
    }