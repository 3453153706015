$primary: #995624;
$input-btn-font-size: 14px;
$secondary: #414A5C;
$font-family-base: 'Circe' !default;
$dark-orange: #e16301;
$dark-grey: #717b86;
$black: #060B23;
$white: #fff;
$light: #C18A71;
$brown:#995624;
$red: red !default;
$dark-brown:#452714;
$dark: #4D2200;
$button-border-radius: 6px;
$input-btn-font-size-sm: .75rem !default;
$input-btn-font-size: .875rem !default;
$input-btn-font-size-lg: .875rem !default;
$input-border-radius: 6px;
$input-border-color: #717b86;
$input-border-radius-lg: 6px;
$input-line-height-lg: 3;
$hr-border-color: #AEADAD;
$dropdown-link-color: $dark-grey;
$table-border-color: #AEADAD;

a {
  color: $dark-orange;
}

$colors: () !default;
$colors: map-merge(("dark-orange": $dark-orange), $colors);

@mixin font-color-classes {
  @each $color,
  $value in $colors {
    .text-#{$color} {
      color: $value !important;
    }
  }
}

@mixin width-classes {
  @for $i from 1 through 1000 {
    $width: $i * 1px;

    .width-#{$i} {
      width: $width;
    }
  }
}

@mixin font-size-classes {
  @for $i from 1 through 1000 {
    $size: $i * 1px;

    .font-size-#{$i} {
      font-size: $size;
    }
  }
}

@include width-classes();
@include font-size-classes();
@include font-color-classes();